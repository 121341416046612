<script>
  import { onMount } from "svelte";
  import { createClient } from "@supabase/supabase-js";

  const supabaseUrl = "https://viswgnjgfpzstdxekczs.supabase.co";
  const supabaseKey =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZpc3dnbmpnZnB6c3RkeGVrY3pzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzc2ODU0MjksImV4cCI6MTk5MzI2MTQyOX0.IzeCqcdBABmKqv7Yz8le1BHCdhEz_YhEtvQ0I9DpGgI";

  const supabase = createClient(supabaseUrl, supabaseKey);

  let email = "";
  let password = "";
  let error_msg = null;

  const login = async () => {
    const {
      data: { user },
      error,
    } = await supabase.auth.signInWithPassword({ email, password });
    // const { error } = await supabase.auth.signInWithPassword({
    //   email,
    //   password,
    // });

    if (error) {
      error_msg = "Invalid email or password";
      console.error(error_msg);
      return;
    }

    window.location.href = "/add-photos/";
  };

  onMount(async () => {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    const { user } = session;

    if (user) {
      window.location.href = "/add-photos/";
    }
  });
</script>

<main>
  {#if error_msg}
    <p class="error">{error_msg}</p>
  {/if}

  <label>
    Email
    <input type="email" bind:value={email} />
  </label>

  <label>
    Password
    <input type="password" bind:value={password} />
  </label>

  <button on:click={login}>Log In</button>
</main>
